








import Vue from 'vue'
import { mapActions } from 'vuex'
import BasePagePreloader from '@/components/BasePagePreloader.vue'

export default Vue.extend({
  name: 'Success',
  components: {
    BasePagePreloader
  },
  data () {
    return {
      state: 'init',
      states: ['init', 'load', 'success', 'error']
    }
  },
  mounted () {
    const url = new URL(location.href)
    this.state = 'load'
    const hash = localStorage.getItem('subscription_hash')
    const need_execute = localStorage.getItem('subscription_need_execute')
    if (!need_execute) {
      this.state = 'success'
      this.addNotification({ type: 'success', body: 'Success' })
      setTimeout(() => {
        window.close()
      }, 3000)
      return
    }
    // if need execute
    if (!hash) {
      this.state = 'error'
      this.addNotification({ type: 'danger', body: 'Subscription error' })
      setTimeout(() => {
        window.close()
      }, 3000)
    } else {
      this.executeSubscription({ hash: hash, token: url.searchParams.get('token') })
        .then(() => {
          this.state = 'success'
          this.addNotification({ type: 'success', body: 'Success' })
        }).catch(e => {
          this.state = 'error'
          this.addNotification({ type: 'danger', body: 'Subscription error' })
        }).then(() => {
          localStorage.removeItem('subscription_hash')
          this.$router.push({ name: 'Profile' })
        })
    }
  },
  methods: {
    ...mapActions('plans', ['executeSubscription']),
    ...mapActions('notifications', ['addNotification'])
  }
})
